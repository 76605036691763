<template>
<section class="wrapper bg-light">
	<div class="container py-14 py-md-16">
		<div class="row align-items-center mb-10">

			<h2 class="display-4 mb-0"
					v-html="post.titre" />
		</div>
		<!--/.row -->
		<div class="row gx-lg-8 gx-xl-11 gy-10 blog grid-view">
			<div class="col-lg-9">
				<article class="post">
					<div class="post-slider rounded mb-6">

						<img :src="post.image"
								 class=""
								 style="width: 100%;"
								 alt="" />
					</div>


					<!-- /.basic-slider -->

					<!-- /.post-slider -->
					<div class="post-header mb-5">
						<div class="post-category text-line">
							<a href="#"
								 class="hover"
								 rel="category"
								 v-html="post.categorie" />
						</div>
						<!-- /.post-category -->
						<h2 class="post-title mt-1 mb-4"
								v-html="post.soustitre" />

						<ul class="post-meta mb-0">
							<li class="post-date"><i class="uil uil-calendar-alt"></i><span v-html="post.last_change" /></li>
							<li class="post-author"><i class="uil uil-user"></i><span v-html="post.auteur" /></li>

						</ul>
						<!-- /.post-meta -->
					</div>
					<!-- /.post-header -->
					<div class="post-content ">
						<p class="lead"
							 v-html="post.texte" />
					</div>
					<!-- /.post-content -->
				</article>
				<!-- /.post -->
			</div>
			<!-- /column -->
			<div class="col-lg-3"
					 style="border-left : 1px solid gray">
				<div class="row gy-10">

					<!-- /column -->
					<div class="col-md-6 col-lg-12"
							 v-for="(item, index) in list"
							 :key="index">
						<article class="post">
							<figure class="overlay overlay-1 hover-scale rounded "><a :href="'/post/'+item.page">
									<img :src="item.image"
											 alt="" /></a>
								<figcaption>
									<h5 class="from-top mb-0">Read More</h5>
								</figcaption>
							</figure>


							<!-- /.post-category -->
							<h2 class="post-title h4 mt-n6">
								<a class="link-dark"
									 :href="'/post/'+item.page">{{item.titre}}
								</a>
							</h2>

							<!-- /.post-header -->

							<!-- /.post-footer -->
						</article>
						<!-- /.post -->
					</div>
					<!-- /column -->
				</div>
				<!-- /.row -->
			</div>
			<!-- /column -->
		</div>
		<!-- /.row -->
	</div>
	<!-- /.container -->
</section>
<!-- /section -->
</template>


<script>
export default {
	name: 'post',
	components: {},
	props: {
		page: String,
		sidebar: Boolean
	},
	data: () => ({
		post: [],
		list: [],
	}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {
		this.pageinterne = this.page;
		if (this.pageinterne == undefined) this.pageinterne = this.$route.params.id;

		this.charge_page()
	},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {},
	methods: {
		charge_page() {
			let opt = [];
			opt["debug"] = true
			opt["api"] = "post"
			opt["page"] = this.pageinterne
			this.$store.dispatch('post', opt).then(response => {
				if (response != undefined) {
					this.post = response.data.data
					this.list = response.data.liste

					this.post.texte = this.post.texte.replaceAll('\n', '<br/>')
					this.post.texte = this.post.texte.replaceAll('', "'")
				}
			})
		}
	},
	watch: {
		page: function () {
			this.pageinterne = this.page;
			this.charge_page()
		}
	}
}
</script>

<style  >
table {
	width: 100%
}

td {
	font-family: courier;
	font-size: 80%;
	text-align: right;
}
</style>
